import { IJuryMember } from './types';

export const juryMembers: IJuryMember[] = [
  {
    name: 'Алексей Сальников',
    image: '/ebukva-promo/jury/01.png',
  },
  {
    name: 'Зоя Яровицына',
    image: '/ebukva-promo/jury/02.png',
  },
  {
    name: 'Ксения Буржская',
    image: '/ebukva-promo/jury/03.png',
  },
  {
    name: 'Николай Жаринов',
    image: '/ebukva-promo/jury/04.png',
  },
  {
    name: 'Анна Матвеева',
    image: '/ebukva-promo/jury/05.png',
  },
  {
    name: 'Антон Комолов',
    image: '/ebukva-promo/jury/06.png',
  },
  {
    name: 'Сергей Чонишвили',
    image: '/ebukva-promo/jury/07.png',
  },
  {
    name: 'Марьяна Спивак',
    image: '/ebukva-promo/jury/08.png',
  },
  {
    name: 'Константин Михайлов',
    image: '/ebukva-promo/jury/09.png',
  },
  {
    name: 'Прохор Чеховской',
    image: '/ebukva-promo/jury/10.png',
  },
];
