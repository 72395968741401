import cn from 'classnames';
import { NotificationsDropdown } from 'modules/header/components/NotificationsDropdown';
import {
  MAX_NOTIFICATIONS_ITEM_DROPDOWN_MENU,
  MAX_NUMBER_BADGE,
} from 'modules/header/constants';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge } from 'components/UIKit/Badge';
import { Dropdown } from 'components/UIKit/Dropdown';
import { FunctionalButton } from 'components/UIKit/FunctionalButton';
import { Icon } from 'components/UIKit/Icon';

import { TAppState } from 'sp-redux';

import s from './NotificationBlock.module.scss';

export const NotificationBlock: FC = () => {
  const { notificationsCounts, notifications } = useSelector(
    (appState: TAppState) => ({
      notificationsCounts: appState.notificationsCenter.counters.total,
      notifications: appState.notificationsCenter.notifications,
    }),
  );
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const handleMenuToggle = (): void => {
    setMenuOpen(prevState => !prevState);
  };

  const filteredNotification = notifications.total
    .filter(item => !item.has_been_read)
    .slice(0, MAX_NOTIFICATIONS_ITEM_DROPDOWN_MENU);

  const handleCloseDropDown = (): void => {
    setMenuOpen(false);
  };

  return (
    <Dropdown onClose={handleCloseDropDown} isOpen={isMenuOpen}>
      <div className={s.wrapper}>
        <FunctionalButton onClick={handleMenuToggle}>
          <Icon
            className={s.icon}
            size={24}
            icon="notification"
            color="black"
          />
          <Badge
            className={cn(s.badge, {
              [s.badgeVisible]: notificationsCounts !== 0,
            })}
            count={
              notificationsCounts < MAX_NUMBER_BADGE
                ? `${notificationsCounts}`
                : '9+'
            }
          />
        </FunctionalButton>
        <NotificationsDropdown
          isOpen={isMenuOpen}
          notifications={filteredNotification}
          notificationsCounts={notificationsCounts}
          onClose={handleCloseDropDown}
        />
      </div>
    </Dropdown>
  );
};
