import { sendSelfpubAnalytics } from 'analytics/sendAnalytics';
import cn from 'classnames';
import type { FC, MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import { EBreakpoints } from 'constants/breakpoints';
import { externalServicesUrls } from 'constants/externalServicesUrls';

import { ALink } from 'components/UIKit/ALink';
import { BodyM, T } from 'components/UIKit/Typography';

import { isBrowser, isTestEnvironment } from 'utils/environment';

import { getPlaceSlug } from './helpers/getPlaceSlug';
import type { IBannerData, ILitresBanner } from './types';

import s from './LitresBanner.module.scss';

const sendBannerEvent = (
  eventType: string,
  bannerData: ILitresBanner,
  userId?: string,
): void => {
  sendSelfpubAnalytics({
    event_type: eventType,
    user_id: Number(userId) || 0,
    event_parameters: {
      block_type: 'banners',
      block_title: bannerData.title,
      block_name: bannerData.event_data.place_slug,
      ad_campaign_id: bannerData.event_data.campaign_id,
      campaign_id: bannerData.event_data.campaign_id,
      banner_id: bannerData.event_data.banner_id,
      app_id: bannerData.event_data.app_id,
      place_slug: bannerData.event_data.place_slug,
    },
  });
};

const sendViewEvent = (bannerData: ILitresBanner, userId?: string): void => {
  sendBannerEvent('adv_banner_view', bannerData, userId);
};

const sendClickEvent = (bannerData: ILitresBanner, userId?: string): void => {
  sendBannerEvent('adv_banner_click', bannerData, userId);
};

interface ILitresBannerProps {
  headerBannerData?: ILitresBanner | null;
  isHeader?: boolean;
  isMainPage?: boolean;
  userId?: string;
}

export const LitresBanner: FC<ILitresBannerProps> = ({
  headerBannerData,
  isHeader,
  isMainPage,
  userId,
}) => {
  const [bannerData, setBannerData] = useState<ILitresBanner | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const copyTextToClipboard = async (): Promise<void> => {
    await navigator.clipboard.writeText(bannerData?.copyLink ?? '');
  };

  const isMobile = isBrowser && window.innerWidth < EBreakpoints.MD;

  const getLitresBanner = async (): Promise<Response> => {
    const placeSlug = getPlaceSlug(isMainPage);

    let url = isTestEnvironment()
      ? externalServicesUrls.litresBanner.master(placeSlug)
      : externalServicesUrls.litresBanner.production(placeSlug);

    const options = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-id': '2',
        Accept: 'application/json',
      },
    };

    if (isMobile) {
      url = url + '?device_type=mobile';
    }

    return await fetch(url, options);
  };

  const getBannerInfo = async (): Promise<void> => {
    try {
      const data = await getLitresBanner();
      const result = (await data.json()) as {
        payload: { data: IBannerData[] };
      };
      const payload = result.payload.data;

      if (payload.length !== 0) {
        const {
          banner_title,
          image_url,
          transition_link,
          advertising_marking,
          event_data,
        } = payload[0];
        const bannerObject = {
          title: banner_title,
          url: image_url,
          link: transition_link,
          mark: advertising_marking,
          copyLink: transition_link,
          event_data,
        };
        setBannerData(bannerObject);
        sendViewEvent(bannerObject, userId);
      }
    } catch (e) {
      setBannerData(null);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isHeader) {
      getBannerInfo();
    }
  }, []);

  useEffect(() => {
    if (headerBannerData) {
      setBannerData(headerBannerData);
      sendViewEvent(headerBannerData, userId);
    }
  }, [headerBannerData]);

  const advertContent = (
    <BodyM>
      {bannerData?.mark}
      <br /> <br />
      <ALink href="" onClick={copyTextToClipboard}>
        Скопировать ссылку
      </ALink>
    </BodyM>
  );

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleBannerClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    if (bannerData) {
      sendClickEvent(bannerData, userId);
      window.open(bannerData.link, '_blank', 'noreferrer');
    }
  };

  return bannerData ? (
    <div className={s.wrapper}>
      {bannerData.link ? (
        <ALink
          href={bannerData.link}
          dataTestId="litres-banner-link"
          onClick={handleBannerClick}
        >
          {/* Оставляем img, т.к. Image снижает качество при переводе в webp */}
          <img
            src={bannerData.url}
            alt={bannerData.title}
            className={cn(s.picture, { [s.pictureHeader]: headerBannerData })}
          />
        </ALink>
      ) : (
        <img
          src={bannerData.url}
          alt={bannerData.title}
          className={cn(s.picture, { [s.pictureHeader]: headerBannerData })}
        />
      )}
      {bannerData.mark && (
        <div
          className={cn(s.nameplate, { [s.nameplateHeader]: headerBannerData })}
          onClick={handleOpen}
        >
          <T>Реклама</T>{' '}
        </div>
      )}
      {isOpen && (
        <div className={s.overlay} onClick={handleClose}>
          <div className={s.content}>{advertContent}</div>
        </div>
      )}
    </div>
  ) : null;
};
