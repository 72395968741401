import type { FC } from 'react';

import { H1 } from 'components/UIKit/Typography';

import s from './StaticInfo.module.scss';

interface IStaticInfoPropsType {
  staticTitle: string;
  staticText: string;

  className?: string;
}

export const StaticInfo: FC<IStaticInfoPropsType> = ({
  staticTitle,
  staticText,
  className,
}) => {
  return (
    <section className={className}>
      <H1 className={s.title}>{staticTitle}</H1>
      <div
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: staticText,
        }}
      />
    </section>
  );
};
