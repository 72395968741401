import { ICollapseListData } from 'components/UIKit/CollapseList';

import { EBukvaJury } from './EBukvaJury/EBukvaJury';

export const ebukvaData: ICollapseListData[] = [
  {
    collapseId: '1',
    headerText: 'Звёздное жюри',
    headerIcon: 'star24x24',
    bodyText: <EBukvaJury />,
  },
  {
    collapseId: '2',
    headerText: 'Общий призовой фонд 24 млн. рублей',
    headerIcon: 'royaltyOutline24x24',
    bodyText: (
      <ul>
        <li>Главные призы «Книга года» и «Голос года» 300 000 рублей</li>
        <li>Продвижение в Литрес всем финалистам конкурса</li>
        <li>14 спецноминаций и призы от партнеров</li>
      </ul>
    ),
  },
  {
    collapseId: '3',
    headerText: 'Прием заявок завершен',
    headerIcon: 'calendar24x24',
    bodyText: (
      <ul>
        <li>Лонг-лист будет объявлен в сентябре 2024 года.</li>
        <li>Шорт-лист будет опубликован в декабре 2024 года.</li>
        <li>Победители будут названы в марте 2025 года.</li>
      </ul>
    ),
  },
];
