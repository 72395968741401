import { logFirebaseEvent } from 'analytics/firebase';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, useModal } from 'components/UIKit/Modal';

import { openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';
import { clearErrors } from 'sp-redux/slices/userRegistration';

import { RestorePasswordForm } from '../RestorePasswordForm';

export const DialogRestorePassword: FC = () => {
  const { modalProps } = useModal({
    type: EUiModalTypes.restorePassword,
    title: 'Восстановление пароля',
    width: '364px',
  });

  const dispatch = useDispatch();

  const signUpHandler = (): void => {
    logFirebaseEvent('popup_registration_view', {
      source: 'registration_form',
    });
    dispatch(clearErrors());
    dispatch(openModal(EUiModalTypes.signUp));
  };

  return (
    <Modal {...modalProps}>
      <RestorePasswordForm signUpHandler={signUpHandler} />
    </Modal>
  );
};
