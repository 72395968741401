import { createAsyncThunk } from '@reduxjs/toolkit';
import { EStatisticsTypes } from 'modules/statistics/pages/StatisticsPage/types';
import { EBooksTypes } from 'modules/statistics/types';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IStatisticsChartThunkProps {
  art_type: EBooksTypes;
  metrics: EStatisticsTypes[];
  from_date: number;
  to_date: number;
  art_ids?: number[];
  user_id?: string | string[];
  context?: GetServerSidePropsContext;
}

export const getStatisticsChartThunk = createAsyncThunk(
  'statistics/getStatisticsChart',
  async ({
    art_type,
    metrics,
    from_date,
    to_date,
    art_ids,
    user_id,
    context,
  }: IStatisticsChartThunkProps) => {
    const apiPointName = 'getStatisticsChart';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        art_type,
        metrics,
        from_date,
        to_date,
      },
      queryParams: { art_ids, user_id },
      context,
    });
  },
);
