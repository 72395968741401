import type { IAPIResponse } from 'app.types';

import type { IApiPointsFetchParamsType } from 'constants/apiPoints';

import type { UserInfoType } from 'sp-redux/slices/userInfo/types';

import { doFetch } from 'utils/doFetch';

export const loginRequest = ({
  email,
  password,
}: IApiPointsFetchParamsType['login']['request']): Promise<
  IAPIResponse<UserInfoType, string>
> => {
  const apiPointName = 'login';

  return doFetch<typeof apiPointName>({
    apiPointName,
    body: { email, password },
  });
};
