import type { IAPIResponse } from 'app.types';

import type { IApiPointsFetchParamsType } from 'constants/apiPoints';

import type { IRegistrationResponseType } from 'sp-redux/slices/userRegistration/types';

import { doFetch } from 'utils/doFetch';

export const registrationRequest = ({
  email,
  password,
  referral,
  params,
}: IApiPointsFetchParamsType['registration']['request']): Promise<
  IAPIResponse<IRegistrationResponseType, string>
> => {
  const apiPointName = 'registration';

  return doFetch<typeof apiPointName>({
    apiPointName,
    queryParams: params,
    body: { email, password, referral },
  });
};
