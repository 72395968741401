import type { IAPIResponse } from 'app.types';

import type { IApiPointsFetchParamsType } from 'constants/apiPoints';

import { IPublisherInfoType } from 'sp-redux/slices/publisher/types';

import { doFetch } from 'utils/doFetch';

export const loginPublisherRequest = ({
  username,
  password,
}: IApiPointsFetchParamsType['publisherLogin']['request']): Promise<
  IAPIResponse<IPublisherInfoType, string>
> => {
  const apiPointName = 'publisherLogin';

  return doFetch<typeof apiPointName>({
    apiPointName,
    body: { username, password },
  });
};
