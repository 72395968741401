import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { SchemaOf } from 'yup';

import {
  oneFieldAsyncValidation,
  oneFieldSyncValidation,
  simpleAsyncValidation,
  simpleSyncValidation,
} from 'utils/simpleValidation';

interface IReturned<ObjectShape> {
  validationErrors: TErrorRecord; // объект ошибок {поле, сообщение}
  validateSingleFieldAsync: (field: keyof ObjectShape) => Promise<boolean>; // асинхронная валидация одного поля
  validateAllFieldsAsync: () => Promise<boolean>; // асинхронная валидация всех полей
  validateSingleFieldSync: (field: keyof ObjectShape) => boolean; // синхронная валидация одного поля
  validateAllFieldsSync: () => boolean; // синхронная валидация всех пoлей
  debouncedValidate: (field: keyof ObjectShape) => Promise<boolean> | undefined; //отложенная валидация одного поля
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrors: (field: any) => void;
}

export type TErrorRecord = Record<string, string>;

export function useValidation<ObjectShape>(
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
  timeout = 0,
): IReturned<ObjectShape> {
  const [errors, setErrors] = useState<TErrorRecord>({});

  async function validateSingleFieldAsync(
    field: keyof ObjectShape,
  ): Promise<boolean> {
    const foundErrors = await oneFieldAsyncValidation<ObjectShape>(
      field,
      object,
      schema,
    );
    return processErrors(foundErrors, field);
  }

  async function validateAllFieldsAsync(): Promise<boolean> {
    const foundErrors = await simpleAsyncValidation<ObjectShape>(
      object,
      schema,
    );
    return processErrors(foundErrors);
  }

  function validateSingleFieldSync(field: keyof ObjectShape): boolean {
    const foundErrors = oneFieldSyncValidation<ObjectShape>(
      field,
      object,
      schema,
    );
    return processErrors(foundErrors, field);
  }

  function validateAllFieldsSync(): boolean {
    const foundErrors = simpleSyncValidation<ObjectShape>(object, schema);
    return processErrors(foundErrors);
  }

  function processErrors(
    foundErrors: { inner: { path: string; message: string }[] } | undefined,
    field?: keyof ObjectShape,
  ): boolean {
    let newErrors: TErrorRecord = { ...errors };
    let isHaveValidationErrors = false;

    if (foundErrors) {
      isHaveValidationErrors = true;

      //inner — это массив объектов ошибок, который oтдаёт yup
      foundErrors.inner.forEach(({ path, message }) => {
        newErrors[path] = message;
      });
    } else {
      if (field) {
        //создаём объект, в котором есть все поля ошибок, кроме целевого
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [field]: value, ...errorsWithoutField } = newErrors;

        newErrors = errorsWithoutField as TErrorRecord;
      }
    }

    if (Object.keys(newErrors).length === 0) {
      isHaveValidationErrors = false;
    }

    setErrors(newErrors);

    return isHaveValidationErrors;
  }

  const debouncedValidate = useDebouncedCallback((field: keyof ObjectShape) => {
    return validateSingleFieldAsync(field);
  }, timeout);

  return {
    setErrors: setErrors,
    validationErrors: errors,
    validateSingleFieldAsync,
    validateAllFieldsAsync,
    validateSingleFieldSync,
    validateAllFieldsSync,
    debouncedValidate,
  };
}
