import type { FC } from 'react';

import { Illustration } from 'components/UIKit/Illustration';
import type { TIllustrationTypes } from 'components/UIKit/Illustration/types';
import { BodyL400, H3 } from 'components/UIKit/Typography';

import { typografFixText } from 'utils/typograf';

import { Titles } from '../Titles';
import { OFFERS_BLOCKS } from './constants';

import s from './OffersBlock.module.scss';

export const OffersBlock: FC = () => {
  return (
    <>
      <Titles
        title="Мы вам предлагаем"
        subtitle="30 миллионов читателей на Литрес и у партнеров"
        dataTestIdTitle="offer_title"
        dataTestIdSubtitle="offer_subtitle"
      />

      <div className={s.blocks}>
        {OFFERS_BLOCKS.map(({ id, title, subtitle, icon }) => (
          <div className={s.block} key={id} data-testid="offer_block">
            <Illustration
              imageType={icon as TIllustrationTypes}
              width={112}
              height={112}
            />
            <H3 className={s.title}>{typografFixText(title)}</H3>
            <BodyL400 className={s.subtitle}>
              {typografFixText(subtitle)}
            </BodyL400>
          </div>
        ))}
      </div>
    </>
  );
};
