import cn from 'classnames';
import type { SVGProps, FC } from 'react';

import s from './CircularProgress.module.scss';

type TProps = SVGProps<SVGSVGElement>;

export const CircularProgress: FC<TProps> = props => {
  const { className } = props;

  return (
    <svg
      className={cn(s.loader, className)}
      viewBox="0 0 24 24"
      data-testid="loader"
    >
      <path
        className={s.track}
        d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm9-6.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Z"
      />
      <path
        className={s.progress}
        d="M11 4.11C11 3.5 11.5 3 12.11 3A8.89 8.89 0 0 1 21 11.89a1.11 1.11 0 0 1-2.22 0 6.67 6.67 0 0 0-6.67-6.67c-.61 0-1.11-.5-1.11-1.1Z"
      />
    </svg>
  );
};
