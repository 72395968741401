import cn from 'classnames';
import { AdvantagesPlatformAB } from 'modules/main-page/components/AdvantagesPlatformAB';
import { BecomeAuthorBlockAB } from 'modules/main-page/components/BecomeAuthorBlockAB';
import { EBukvaPromo } from 'modules/main-page/components/EBukvaPromo';
import { FinalBlock } from 'modules/main-page/components/FinalBlock';
import { HelpsBlock } from 'modules/main-page/components/HelpsBlock';
import { OffersBlock } from 'modules/main-page/components/OffersBlock';
import { SuccessStoriesBlock } from 'modules/main-page/components/SuccessStoriesBlock';
import { WaitBlock } from 'modules/main-page/components/WaitBlock';
import type { CSSProperties, FC } from 'react';

import { LitresBanner } from 'components/shared/LitresBanner';

import s from './MainPage.module.scss';

export const MainPage: FC<{
  tiltPhoneStyle: CSSProperties;
  tiltBookStyle: CSSProperties;
  isMainPageAB?: boolean;
}> = ({ tiltPhoneStyle, tiltBookStyle, isMainPageAB }) => {
  return (
    <>
      <div className={s.container}>
        {isMainPageAB ? (
          <BecomeAuthorBlockAB
            tiltPhoneStyle={tiltPhoneStyle}
            tiltBookStyle={tiltBookStyle}
          />
        ) : (
          <WaitBlock />
        )}

        <div className={s.block}>
          {isMainPageAB ? <AdvantagesPlatformAB /> : <OffersBlock />}
        </div>
      </div>

      <div className={s.container}>
        <LitresBanner isMainPage />
      </div>

      <div className={cn(s.container)}>
        <div className={s.successStoriesBlock}>
          <SuccessStoriesBlock />
        </div>
      </div>

      <EBukvaPromo />

      <div className={cn(s.container, s.last)}>
        <div className={s.block}>
          <HelpsBlock />
        </div>
      </div>
      <FinalBlock />
    </>
  );
};
