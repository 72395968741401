import { sendGoogleAnalytics } from 'analytics/sendAnalytics';
import { phone } from 'modules/footer/constants';
import Image from 'next/image';
import { FC } from 'react';

import { SOCIALS } from 'constants/social';

import { BodyM400, H4 } from 'components/UIKit/Typography';

import s from './FooterContacts.module.scss';

export const FooterContacts: FC = () => {
  const handleSocialClick = (key: string): void => {
    sendGoogleAnalytics({
      name: 'Footer',
      category: 'Contact',
      action: key,
      label: 'Click',
    });
  };

  const handlePhoneClick = (): void => {
    sendGoogleAnalytics({
      name: phone.analytics.name,
      category: phone.analytics.category,
      action: phone.analytics.action,
      label: 'Click',
    });
  };

  return (
    <>
      <ul className={s.socialList}>
        {SOCIALS.map(({ key, link, icon }) => (
          <li key={key}>
            <a
              {...link}
              onClick={(): void => handleSocialClick(key)}
              className={s.socialListLink}
            >
              <Image src={icon.src} width={32} height={32} alt={key} />
            </a>
          </li>
        ))}
      </ul>
      <a
        href={phone.href}
        title={phone.title}
        className={s.contactLink}
        onClick={handlePhoneClick}
      >
        <H4>{phone.text}</H4>
      </a>
      <BodyM400>{phone.description}</BodyM400>
    </>
  );
};
