import type { IUserDirectAgreementType } from '../User/types';

export enum EProfileStatuses {
  /**
   * Юзер не отсылал профиль на модерацию
   * Дефолтное значение
   */
  new = 'NEW',
  /**
   * Юзер отправит (сохранил) профиль на ревью модератора
   * Статус с опечаткой с бекенда (кек)
   */
  submitted = 'SUBMITTED',
  /**
   * Профиль был взят на модераию, модератор отметил его в очереди
   */
  unverified = 'UNVERIFIED',
  /**
   * Модератор поставил статус "требует доработки" и добавил коммент, описывающий эти доработки
   * a comment describing the revisions
   */
  rejected = 'REJECTED',
  /**
   * Модератор проверил профиль и заапрувил его
   */
  verified = 'VERIFIED',
}

export interface IProfile {
  birthday: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  moderation_comment: null | string;
  status: EProfileStatuses;
  direct_agreement?: IUserDirectAgreementType[];
}

export interface IModeratorProfile {
  id: number;
  status: string;
  email: string;
  full_name: string;
  books_on_moderation: number;
  referral_name: string;
  date_modified: string;
  moderator_email: null; //null в Swagger.email модератора
}
