import { pageUrls } from 'constants/pagesUrls';

import { IProfileMenuItem } from './types';

export const PROFILE_MODERATOR_ITEMS: IProfileMenuItem[] = [
  {
    id: 'menu-new-modearation',
    label: 'Новая модераторская',
    link: pageUrls.moderation.queue.new,
  },
  {
    id: 'menu-moderation-queue',
    label: 'Очередь на модерацию',
    link: pageUrls.moderation.queue.index,
  },
  {
    id: 'menu-my-modearation-queue',
    label: 'У меня на модерации',
    link: pageUrls.moderation.queue.my,
  },
  {
    id: 'menu-modearation-circulation-queue',
    label: 'Очередь тиражей на модерацию',
    link: pageUrls.moderation.circulation.queue.index,
  },
  {
    id: 'menu-final-modearation-my-queue',
    label: 'Тиражи у меня на модерации',
    link: pageUrls.moderation.circulation.queue.my,
  },
];
