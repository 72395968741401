import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { pageUrls } from 'constants/pagesUrls';

import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { ModalFooter } from 'components/UIKit/Modal';
import { BodyM } from 'components/UIKit/Typography';

import { restorePasswordThunk } from 'sp-redux/thunks/userRegistration/restorePasswordThunk';

import type { IRestoreStepTwoType } from './types';

import s from './RestorePasswordForm.module.scss';

export const RestoreStepTwo: FC<IRestoreStepTwoType> = ({
  email,
  isLoading,
  setIsLoading,
}) => {
  const [counterRepeatThunk, setCounterRepeatThunk] = useState(30);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (counterRepeatThunk === 0) {
      setIsLoading(false);
      return;
    }
    const interval = setInterval(() => {
      setCounterRepeatThunk(isDisabled => isDisabled - 1);
    }, 1000);
    return (): void => {
      clearInterval(interval);
    };
  });

  function repeatSendingHandler(): void {
    setIsLoading(false);
    setCounterRepeatThunk(30);
    dispatch(restorePasswordThunk({ email }));
  }

  return (
    <>
      <div className={s.root}>
        <BodyM>
          Мы выслали на ваш e-mail <b>{email}</b> инструкцию по восстановлению
          пароля.&nbsp; <br />
          <br /> Если письмо не пришло, убедитесь что оно не попало в в
          спам.&nbsp; <br /> <br /> Если возникнут сложности напишите нам на
          <ALink href="mailto:support@selfpub.ru"> support@selfpub.ru</ALink>
        </BodyM>
      </div>
      <ModalFooter>
        <Button
          size="small"
          look="secondary"
          onClick={repeatSendingHandler}
          disabled={isLoading}
        >
          Выслать повторно {isLoading ? `${counterRepeatThunk} сек` : ''}
        </Button>
        <Button size="small" href={pageUrls.home}>
          На главную
        </Button>
      </ModalFooter>
    </>
  );
};
