import { OAUTH_LITRES_CLIENT_ID } from 'modules/litres/constants';

export const externalServicesUrls = {
  litresBanner: {
    master: (placeSlug: string): string =>
      `https://master.api.b.litres.ru/advertising/api/services/selfpub/places/${placeSlug}/banners`,
    production: (placeSlug: string): string =>
      `https://api.litres.ru/advertising/api/services/selfpub/places/${placeSlug}/banners`,
  },
  litresReferralProgram:
    'https://www.litres.ru/o-kompanii/partnerskie-programmy/referalnye-partnery/#q-2',
  litresReviewsFaq: 'https://www.litres.ru/pravila-napisaniya-otzyvov/',
  artInfo: {
    master: 'https://master.api.b.litres.ru/foundation/api/arts/',
    production: 'https://api.litres.ru/foundation/api/arts/',
  },
  artComments: {
    master: (litresArtId: number): string =>
      `https://master.api.b.litres.ru/foundation/api/arts/${litresArtId}/reviews`,
    production: (litresArtId: number): string =>
      `https://api.litres.ru/foundation/api/arts/${litresArtId}/reviews`,
  },
  oauthLitresLink: {
    link: (clientId: string): string =>
      `https://www.litres.ru/pages/oauth_authorize/?client_id=${OAUTH_LITRES_CLIENT_ID}&response_type=code&state=${clientId}`,
  },
  nalogRuPersonalAccount: 'https://lknpd.nalog.ru/auth/login',
  selfpubAnalytics: 'https://api.litres.ru/tracker/api/event-selfpub',
};
