export interface ICheckboxItemsTypes {
  name: string;

  label?: JSX.Element;
}

export interface IAllFiltersComponentsTypes {
  statusGroup: JSX.Element;
  timerGroup: JSX.Element;
  moderatorGroup: JSX.Element;
  profileAuthorAutocomplete: JSX.Element;
  licenceGroup: JSX.Element;
  authorStatusGroup: JSX.Element;
  postModerationSwitch: JSX.Element;
  plagiarySwitch: JSX.Element;
  [whoReturnGroup: string]: JSX.Element;
}

export enum EResultType {
  BOOK = 'book',
  PROFILE = 'profile',
}
