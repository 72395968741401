import { logFirebaseEvent } from 'analytics/firebase';
import { sendYandexAnalytics } from 'analytics/sendAnalytics';
import type { IApiResponseErrorField } from 'app.types';
import { useValidation } from 'hooks/useValidation';
import { loginRequest } from 'modules/profile/requests/loginRequest';
import type { FormEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import type { SchemaOf } from 'yup';

import { Button } from 'components/UIKit/Button';
import { InputText } from 'components/UIKit/InputText';
import { ModalFooter } from 'components/UIKit/Modal';
import { BodyL, BodyM } from 'components/UIKit/Typography';

import type { TAppState } from 'sp-redux';

import type { ILoginFormType, IProps } from './types';

import s from './LoginForm.module.scss';

const schema: SchemaOf<ILoginFormType> = yup.object().shape({
  email: yup.string().min(1).required('Введите логин'),
  password: yup.string().min(1).required('Введите пароль'),
});

export const LoginForm: FC<IProps> = ({
  resetPasswordHandler,
  signUpHandler,
  handleSuccess,
}) => {
  const state = useSelector((st: TAppState) => st.userInfo);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otherErrors, setOtherErrors] = useState<
    IApiResponseErrorField<string>[]
  >([]);

  const { validationErrors, validateAllFieldsAsync, debouncedValidate } =
    useValidation<ILoginFormType>({ email, password }, schema);

  useEffect(() => {
    if (state.isLogged) {
      return;
    }

    if (state.errors.length > 0) {
      const errorsArray = state.errors.map(e => ({
        field: e.field,
        messages: Array.isArray(e.messages) ? e.messages[0] : e.messages,
      }));
      setOtherErrors(errorsArray);
    }
  }, [state.errors, state.isLogged]);

  async function doLogin(evt: FormEvent): Promise<void> {
    setIsLoading(true);
    setOtherErrors([]);
    evt.preventDefault();

    const hasErrors = await validateAllFieldsAsync();

    if (hasErrors) {
      setIsLoading(false);
      return;
    }

    await loginRequest({
      email,
      password,
    }).then(result => {
      if (result.errors.length) {
        setOtherErrors(result.errors);
      } else {
        const action = 'login_success';
        logFirebaseEvent(action);
        sendYandexAnalytics({
          action,
          goalParams: { user_id: result.result.uuid },
        });
        if (handleSuccess) {
          handleSuccess();
        }
      }
    });
    setIsLoading(false);
  }

  const hasErrorMessage = otherErrors.length > 0;

  return (
    <form onSubmit={doLogin}>
      <div className={s.root}>
        <InputText
          labelText="Ваша электронная почта"
          value={email}
          onChange={(value): void => {
            setEmail(value);
          }}
          debouncedValidate={(): void => {
            debouncedValidate('email');
          }}
          errorMessage={validationErrors.email}
          autocomplete="email"
          hasError={hasErrorMessage}
        />
        <InputText
          labelText="Ваш пароль"
          value={password}
          type="password"
          onChange={(value): void => {
            setPassword(value);
          }}
          debouncedValidate={(): void => {
            debouncedValidate('password');
          }}
          errorMessage={validationErrors.password}
          autocomplete="current-password"
          hasError={hasErrorMessage}
        />

        {hasErrorMessage &&
          otherErrors.map(er => {
            return (
              <div key={er.messages[0]}>
                {typeof er.messages === 'string' ? (
                  <BodyL className={s.withError}>{er.messages}</BodyL>
                ) : (
                  er.messages.map(message => (
                    <BodyL key={message} className={s.withError}>
                      {message}
                    </BodyL>
                  ))
                )}
              </div>
            );
          })}

        <BodyM>
          <span
            className={s.linkRestorePassword}
            onClick={resetPasswordHandler}
          >
            Забыли пароль ?
          </span>
        </BodyM>
      </div>
      <ModalFooter>
        <Button size="medium" look="tertiary" onClick={signUpHandler}>
          Зарегистрироваться
        </Button>
        <Button size="medium" type="submit" disabled={isLoading}>
          Войти
        </Button>
      </ModalFooter>
    </form>
  );
};
