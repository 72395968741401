import { ESeriesState } from '../entities/Series/constant';

export const pageUrls = {
  audioBooks: {
    index: '/audiobooks/',
    new: {
      master: '/audiobooks/master/new',
      configurator: '/audiobooks/configure/new',
    },
    master: '/audiobooks/master/',
    configurator: '/audiobooks/configure/',
  },
  home: '/',
  books: {
    index: '/books/',
    bookEditor: '/books/',
    chapterEditor: '/books/',
    new: '/books/new/',
    import: '/books/import/',
    newDraft: '/books/new/?type=draft',
    contest: {
      index: '/books/contests/',
      success: '/books/contests/success/',
    },
    newFanfic: '/books/new/?type=fanfic',
  },
  series: {
    index: '/series/',
    in_progress: `/series/?state=${ESeriesState.in_progress}`,
    published: `/series/?state=${ESeriesState.published}`,
    new: '/series/new',
    edit: '/series/',
  },
  status: {
    index: '/status/',
  },
  persons: {
    index: '/persons/',
  },
  orders: {
    index: '/orders-new/',
    proofread: '/orders/proofread/',
    circulation: {
      edit: '/orders-new/circulation/',
    },
    promotion: {
      edit: '/orders-new/promotion/',
    },
  },
  state: 'https://selfpub.ru/status/',
  feedback: {
    index: '/feedback/',
  },
  statistics: {
    index: '/statistics/',
    info: '/statistics/info',
    payments: '/statistics/payments/',
    legasyTab: '/statistics/?currentTab=payments',
    verification: '/statistics/verification/',
    reports: {
      royalty: '/statistics/reports/?currentTab=royalty',
      reading: '/statistics/reports/?currentTab=reading',
    },
    royaltyPayments: '/statistics/royalty-payments/',
    naturalPerson: '/statistics/natural-person/',
  },
  about: {
    index: '/about/',
  },
  domains: {
    index: '/domains/',
  },
  offer: {
    index: '/offer/',
  },
  offer_upgrade: {
    index: '/offer_upgrade/',
  },
  offer_non_resident: {
    index: '/offer_non_resident_lo/',
  },
  non_resident_offer_upgrade: {
    index: '/non-resident-new-offer/',
  },
  offer_free_fanfic: {
    index: '/offer_free_fanfic/',
  },
  agreement: {
    index: '/agreement/',
  },
  privacy: {
    index: '/privacy/',
  },
  privacy_litres_operations: {
    index: '/privacy_litres_operations/',
  },
  blog: {
    index: 'https://blog.selfpub.ru/',
    promo: 'https://blog.selfpub.ru/promo/',
    promoAdvice: 'https://blog.selfpub.ru/promo-advice',
    partnerProgram: 'https://blog.selfpub.ru/partner-programm',
    circulation: 'https://blog.selfpub.ru/circulation',
    publishBook: 'https://blog.selfpub.ru/publish-book/',
    makingWonderfulCover: 'https://blog.selfpub.ru/making-wonderful-cover',
  },
  ebukva: {
    index: 'https://ebukva.litres.ru/',
  },
  account: {
    login: '/account/login/',
    logout: '/account/logout/',
    registration: '/account/registration/',
    profile: '/account/profile/',
  },
  faq: {
    index: '/faq/',
    recommendation: '/faq/recomendation/',
    firstStep: '/faq/first-step/',
    printOnDemand: '/faq/print-on-demand/',
    pricing: '/faq/pricing/',
    draft: '/faq/chernoviki/',
    recommendationsDraft: '/faq/chernoviki/#newchapter',
    registration: '/faq/first-step/#new-account',
    restrictions: '/faq/restrictions/#illustration-cco',
    lgbtRestrictions: '/faq/restrictions/#newlaw',
    discounts: '/faq/discounts/',
    glossaryaudio: '/faq/audio/#glossaryaudio',
    publishaudio: '/faq/publishaudio/',
    ebook: '/faq/ebook/',
    circulation: {
      index: '/faq/circulation/',
      returnCirculation: '/faq/circulation/#return-circulation',
      deliveryCirculation: '/faq/circulation/#delivery-circulation',
    },
    license: '/faq/license/',
  },
  services: {
    index: '/services/',
    circulation: {
      index: '/services/circulation/',
      order: '/services/circulation/order/',
    },
    promotion: {
      index: '/services/promotion/',
      order: '/services/promotion/order/',
      media: '/services/promotion/media/',
      artOrder: '/services/promotion/art/order/',
    },
    editing: {
      index: '/services/editing/',
      order: '/services/editing/order/',
    },
    proofread: {
      index: '/services/proofread/',
      order: '/services/proofread/order/',
    },
    pagemaker: {
      index: '/services/pagemaker/',
      order: '/services/pagemaker/order/',
    },
    annotation: {
      index: '/services/annotation/',
      order: '/services/annotation/order/',
    },
    assistant: {
      index: '/services/assistant/',
      order: '/services/assistant/order/',
    },
    audio: {
      index: '/services/audio/',
      readers: '/services/audio/readers/',
      order: '/services/audio/order/',
    },
    design: {
      index: '/services/design/',
      order: '/services/design/order/',
    },
    booktrailerNew: {
      index: '/services/booktrailer/',
      order: '/services/booktrailer/order/',
    },
    promotionPopup: '/services/promotion-popup/',
    servicesIndex: 'https://services.selfpub.ru/',
    designOrder: 'https://services.selfpub.ru/design',
    audioOrder: 'https://services.selfpub.ru/audiobook#rec481057646',
    uslugi: 'https://services.selfpub.ru/uslugi',
    audioBook: 'https://services.selfpub.ru/audiobook',
    booktrailer: 'https://services.selfpub.ru/booktrailer',
    blogger: 'https://services.selfpub.ru/post-3bloger',
    bannerLitres: 'https://services.selfpub.ru/banner-main-litres',
    social: 'https://services.selfpub.ru/anons-socialnyi-seti',
    topSales: 'https://services.selfpub.ru/paket-top-prodaji',
    popular: 'https://services.selfpub.ru/promo-art-popular',
    vk: 'https://services.selfpub.ru/target-vk-250',
    genreBanner: 'https://services.selfpub.ru/banner-janr-sboku',
    stories: 'https://services.selfpub.ru/storis-litres',
    searchBanner: 'https://services.selfpub.ru/banner-search-litres',
    anons: 'https://services.selfpub.ru/pr-social-litres',
  },
  moderation: {
    queue: {
      index: '/moderation/queue/',
      my: '/moderation/queue/my/',
      final: '/moderation/queue/final/',
      new: '/moderation/queue/new/',
    },
    book: {
      index: '/moderation/book/',
      new: '/moderation/book/new/',
    },
    profile: {
      index: '/moderation/profile/',
      new: '/moderation/profile/new/',
    },
    circulation: {
      queue: {
        index: '/moderation/circulation/queue/',
        my: '/moderation/circulation/queue/my/',
      },
    },
  },
  landing: {
    selfpub: {
      education: 'https://landing.selfpub.ru/education',
    },
  },
  publish: {
    index: '/publish-book',
  },
  partnershipAgreement: {
    index:
      'https://www.litres.ru/o-kompanii/partneram/partnerskoe-soglashenie/',
  },
  notifications: {
    index: '/notifications',
    books: '/notifications/?type=books',
    promo: '/notifications/?type=promo',
    news: '/notifications/?type=news',
  },
  referral: {
    index: '/referral-program/',
  },
  myBooks: {
    audiobooks: '/books/audiobooks/',
    ebooks: '/books/ebooks/',
    print: '/books/print/',
    stories: '/books/stories/',
    podcasts: '/books/podcasts/',
  },
  podcasts: {
    index: '/podcasts/',
    new: '/podcasts/new/',
  },
  litres: {
    onlyLitres: 'https://www.litres.ru/tags/tolko-na-litres/',
    referralProgram: 'https://www.litres.ru/pages/reader_partner/',
  },
  contacts: {
    index: '/contacts/',
  },
  create: {
    index: '/create/',
  },
  achievements: {
    index: '/achievements/',
  },
};

export const publisherUrls = {
  statistics: {
    readingRoyalty: '/publisher/statistics/royalty-page-reading',
    salesRoyalty: '/publisher/statistics/royalty-page-sales',
  },
  login: '/publisher/login/',
};
