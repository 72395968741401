import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface IGetAudioBookMetaByAudioBookIdSignature {
  bookId: string;
  context?: GetServerSidePropsContext;
}

export const getAudioBookMetaByAudioBookIdThunk = createAsyncThunk(
  'audioBooks/getAudioBookMetaByAudioBookId',
  async ({ bookId, context }: IGetAudioBookMetaByAudioBookIdSignature) => {
    const apiPointName = 'getAudioBookMetaByAudioBookId';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);
