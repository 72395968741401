import cn from 'classnames';
import { FC, ReactNode } from 'react';

import { Collapse } from 'components/UIKit/Collapse';
import { Divider } from 'components/UIKit/Divider';
import { TIconTypes } from 'components/UIKit/Icon';
import { BodyM } from 'components/UIKit/Typography';

import s from './CollapseList.module.scss';

export interface ICollapseListData {
  collapseId: string;
  headerText: string;
  headerIcon?: TIconTypes;
  bodyText: ReactNode;
  initialOpened?: boolean;
}

interface ICollapseListProps {
  collapseData: ICollapseListData[];
  className?: string;
  arrowPosition?: 'left' | 'right';
  showAllBorders?: boolean;
  headerClassName?: string;
  dividerClassName?: string;
  bodyClassName?: string;
  blockClassName?: string;
  isCustomBlock?: boolean;
}

export const CollapseList: FC<ICollapseListProps> = ({
  collapseData,
  className,
  arrowPosition,
  showAllBorders,
  headerClassName,
  dividerClassName,
  bodyClassName,
  blockClassName,
  isCustomBlock,
}) => {
  return (
    <div className={cn(s.wrapper, className)}>
      {collapseData.map(
        (
          {
            collapseId,
            headerText,
            headerIcon,
            bodyText,
            initialOpened,
          }: ICollapseListData,
          index,
        ) => {
          return (
            <div key={collapseId}>
              <Collapse
                headerText={headerText}
                headerIcon={headerIcon}
                localStorageName={collapseId}
                className={cn(s.collapse, headerClassName)}
                classNameBody={cn(s.collapseBody, bodyClassName)}
                arrowPosition={arrowPosition}
                initialOpened={initialOpened}
              >
                <BodyM
                  className={cn(s.block, blockClassName)}
                  Tag={isCustomBlock ? 'div' : 'p'}
                >
                  {bodyText}
                </BodyM>
              </Collapse>
              {(index !== collapseData.length - 1 || !!showAllBorders) && (
                <Divider className={cn(s.divider, dividerClassName)} />
              )}
            </div>
          );
        },
      )}
    </div>
  );
};
