import Image from 'next/image';
import type { FC } from 'react';

import { Illustration } from 'components/UIKit/Illustration';
import {
  BodyL700,
  BodyL400,
  H4,
  HighlightTitle,
  HighlightSubtitle,
} from 'components/UIKit/Typography';

import { typografFixText } from 'utils/typograf';

import { SUCCESS_STORIES } from './constants';

import s from './SuccessStoriesBlock.module.scss';

export const ThirdStory: FC = () => {
  const story = SUCCESS_STORIES[2];

  return (
    <>
      <div className={s.about}>
        <Image src={story.src} height={56} width={56} alt={story.name} />
        <div className={s.info}>
          <BodyL700>{story.name}</BodyL700>
          <BodyL400 className={s.genre}>{story.genre}</BodyL400>
        </div>
      </div>

      <HighlightTitle className={s.modalTitle}>
        {typografFixText(story.text)}
      </HighlightTitle>

      <H4>{typografFixText('Меня заметили на Литрес')}</H4>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Свою первую книгу «Нежная магия Тосканы. Вино, коты и призраки» я опубликовала в Литрес 5 лет назад. В том же месяце она вошла в топ продаж на Литрес, и родился контракт с Эксмо. Сегодня та первая бумажная книга все еще популярна, вышла в нескольких тиражах и изданиях, а у меня за 5 лет издано в бумаге уже 14 книг. Их называют бестселлерами, и четверть тиража новой книги, вышедшей в июне, раскуплена в первый день продаж. Сегодня я лауреат премии Русский Детектив-2022, член жюри сезона в 2023 году, участник главных книжных фестивалей и ярмарок',
        )}
      </BodyL400>

      <div className={s.modalQuote}>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteTop}
        />
        <HighlightSubtitle className={s.modalQuoteText}>
          {typografFixText(
            'Я с нуля создавала новую аудиторию, ведь читателям, привыкшим к моим «серьезным» книгам, не особо интересны детективы',
          )}
        </HighlightSubtitle>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteBottom}
        />
      </div>
      <H4>{typografFixText('Вернулась на Литрес ради экспериментов')}</H4>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'В 2022 году я вернулась на Литрес с детективной серией. Я с нуля создавала новую аудиторию, ведь читателям, привыкшим к моим «серьезным» книгам, не особо интересны детективы. Все получилось! Сегодня по популярности детективная серия обходит электронные версии бумажных книг. Я учусь писательству, учусь продвижению, продолжаю заниматься блогерством. Отношения с Литрес становятся все более тесными: я амбассадор издательских сервисов 2023 года и уже во второй раз член жюри конкурса «КНИГАсветное путешествие»',
        )}
      </BodyL400>
    </>
  );
};
