import { filtersGroup } from 'modules/moderation/components/FiltersModerator/constants';
import { EResultType } from 'modules/moderation/components/FiltersModerator/types';
import { defaultModerationLimit } from 'modules/moderation/constants';

import type { IAllQueueStateType } from './types';
import { EModerationBookTabs } from './types';

export const limit = defaultModerationLimit.toString();

export const initialState: IAllQueueStateType = {
  isFetched: false,
  isFetching: true,
  isFilterFetching: false,
  profiles: [],
  metaProfiles: null,
  moderators: [],
  metaModerators: null,
  allQueueBooksReturns: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  allQueueBooksNew: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  allQueueProfileReturns: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  allQueueProfileNew: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  myBooksQueueReturns: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  myBooksQueueNew: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  myQueueProfilesReturns: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  myQueueProfilesNew: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  finalQueueBooksReturns: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  finalQueueBooksNew: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  finalQueueBooksErrors: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  finalQueueBooksFreezed: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  page: 'allBooks',
  tabName: EModerationBookTabs.allQueueBooksNew,
  counters: null,
  filtersCheckBoxGroup: filtersGroup,
  filtersAutocompleteGroup: {
    mailModerators: [],
    mailProfiles: [],
  },
  filtersSwitchers: {
    postmoderation: false,
  },
  moderationId: null,
  errors: [],
};
