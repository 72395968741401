import { logFirebaseEvent } from 'analytics/firebase';
import router from 'next/router';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageUrls } from 'constants/pagesUrls';

import { Modal } from 'components/UIKit/Modal';

import { TAppState } from 'sp-redux';
import { closeModal, openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';
import { clearErrors } from 'sp-redux/slices/userRegistration';

import { LoginForm } from '../LoginForm';
import { RegistrationForm } from '../RegistrationForm';

export const DialogSignInSignUp: FC = () => {
  const { isOpenModal, modalType, modalFields } = useSelector(
    (appState: TAppState) => {
      return {
        isOpenModal: appState.ui.isOpenModal,
        modalType: appState.ui.modalType,
        modalFields: appState.ui.modalFields,
      };
    },
  );

  const close = (): void => {
    dispatch(closeModal());
  };

  const modalProps = {
    title: modalType === EUiModalTypes.signIn ? 'Вход' : 'Регистрация',
    width: '364px',
    isOpen:
      isOpenModal &&
      (modalType === EUiModalTypes.signUp ||
        modalType === EUiModalTypes.signIn),
    onRequestClose: close,
    modalFields,
  };

  const dispatch = useDispatch();

  const openSignUpModalHandler = (): void => {
    logFirebaseEvent('popup_registration_view', {
      source: 'registration_form',
    });
    dispatch(clearErrors());
    dispatch(openModal(EUiModalTypes.signUp));
  };

  const openSignInModalHandler = (): void => {
    logFirebaseEvent('popup_login_view', { source: 'login_form' });
    dispatch(openModal(EUiModalTypes.signIn));
  };

  const openRestoreModalHandler = (): void => {
    logFirebaseEvent('popup_password_reset_view', {
      source: 'password_reset_form',
    });
    dispatch(clearErrors());
    dispatch(openModal(EUiModalTypes.restorePassword));
  };

  const signInSuccessHandler = (): void => {
    close();
    const { query, push } = router;
    if (typeof query.next !== 'string') {
      push(pageUrls.books.index);
    } else {
      push(query.next);
    }
  };

  const signUpSuccessHandler = (): void => {
    close();
    window.location.href = '/create/';
  };

  return (
    <Modal {...modalProps}>
      {modalType === EUiModalTypes.signIn && (
        <LoginForm
          signUpHandler={openSignUpModalHandler}
          resetPasswordHandler={openRestoreModalHandler}
          close={close}
          handleSuccess={signInSuccessHandler}
        />
      )}
      {modalType === EUiModalTypes.signUp && (
        <RegistrationForm
          handleSwitchToSignIn={openSignInModalHandler}
          handleSuccess={signUpSuccessHandler}
        />
      )}
    </Modal>
  );
};
