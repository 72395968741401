import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { BodyM400, BodyM700 } from 'components/UIKit/Typography';

import { typografFixText } from 'utils/typograf';

import s from './NotificationHeaderCard.module.scss';

interface INotificationHeaderCard {
  title: string;
  text: string;
  created_at: number;
  url: string;
}

export const NotificationHeaderCard: FC<INotificationHeaderCard> = ({
  title,
  text,
  created_at,
  url,
}) => {
  return (
    <ALink href={url} className={s.card}>
      <BodyM700 className={s.title}>{title}</BodyM700>
      <BodyM400 className={s.comment}>{typografFixText(text)}</BodyM400>
      <BodyM400 className={s.date}>
        {formatDistanceToNow(created_at, { addSuffix: true, locale: ru })}
      </BodyM400>
      <div className={s.circle} />
    </ALink>
  );
};
