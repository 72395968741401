import cn from 'classnames';
import type { FC } from 'react';

import { Caption } from 'components/UIKit/Typography';

import s from './Badge.module.scss';

interface IBadgeProps {
  count: string;
  className?: string;
}

export const Badge: FC<IBadgeProps> = props => {
  const { count, className } = props;

  return (
    <div className={cn(s.circle, className)}>
      <Caption className={s.caption}>{count}</Caption>
    </div>
  );
};
