import cn from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';

import s from 'components/UIKit/Alert/Alert.module.scss';
import { Icon } from 'components/UIKit/Icon';
import { BodyL700, BodyM, Caption } from 'components/UIKit/Typography';

import { Button } from '../Button';

export type TAlertIconTypes =
  | 'infoOutline24x24'
  | 'errorOutline24x24'
  | 'checkCircleOutline24x24';

export enum EStatus {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface IAlertProps {
  type: EStatus;

  size?: 'medium' | 'small';
  wrapper?: boolean;
  closed?: boolean;
  className?: string;
  title?: string;
  textInformation?: string | string[] | JSX.Element;
  buttonText?: string;
  buttonDataTestId?: string;
  onButtonClick?: () => void;
  dataTestId?: string;
}

const iconView: Record<string, TAlertIconTypes> = {
  info: 'infoOutline24x24',
  error: 'errorOutline24x24',
  success: 'checkCircleOutline24x24',
};

export const Alert: FC<IAlertProps> = props => {
  const {
    type = EStatus.info,
    size = 'medium',
    wrapper,
    closed,
    className,
    title,
    textInformation,
    buttonText,
    buttonDataTestId,
    onButtonClick,
    dataTestId,
  } = props;

  const [isShowAlertMessage, setIsShowAlertMessage] = useState(true);

  const closeHandler = (): void => {
    setIsShowAlertMessage(false);
  };

  const iconColor = cn(s.alertIcon, {
    [s.small]: size === 'small',
    [s.info]: type === EStatus.info,
    [s.error]: type === EStatus.error,
    [s.success]: type === EStatus.success,
  });

  return (
    <div className={className} data-testid={dataTestId}>
      {wrapper ? (
        isShowAlertMessage && (
          <div
            className={cn(s.alert, {
              [s.small]: size === 'small',
              [s.info]: type === EStatus.info,
              [s.error]: type === EStatus.error,
              [s.success]: type === EStatus.success,
            })}
          >
            <div className={s.alertIconAndTextBlock}>
              <div className={s.roundAlertIcon}>
                <Icon size={24} icon={iconView[type]} className={iconColor} />
              </div>
              {size === 'medium' && (
                <div>
                  {title && <BodyL700 className={s.title}>{title}</BodyL700>}
                  <BodyM className={s.textInformationBlock}>
                    {textInformation}
                  </BodyM>
                </div>
              )}
              {size === 'small' && (
                <div>
                  {title && <BodyL700 className={s.title}>{title}</BodyL700>}
                  <Caption className={s.textInformationBlock}>
                    {textInformation}
                  </Caption>
                </div>
              )}
            </div>
            {buttonText && (
              <Button
                look="tertiary"
                size="small"
                onClick={onButtonClick}
                className={s.button}
                dataTestId={buttonDataTestId}
              >
                {buttonText}
              </Button>
            )}
            {closed && (
              <button
                type="button"
                className={s.closeIcon}
                onClick={closeHandler}
                data-testid="alertCloseButton"
              >
                <Icon icon="closeOutline24x24" size={24} />
              </button>
            )}
          </div>
        )
      ) : (
        <div className={s.alertIconAndTextBlock}>
          <div className={s.roundAlertIcon}>
            <Icon size={24} icon={iconView[type]} className={iconColor} />
          </div>
          {size === 'medium' && (
            <div>
              {title && <BodyL700 className={s.title}>{title}</BodyL700>}
              <BodyM className={s.textInformationBlock}>
                {textInformation}
              </BodyM>
            </div>
          )}
          {size === 'small' && (
            <div>
              {title && <BodyL700 className={s.title}>{title}</BodyL700>}
              <Caption className={s.textInformationBlock}>
                {textInformation}
              </Caption>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
