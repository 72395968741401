import cn from 'classnames';
import { IMainMenuItem } from 'modules/header/types';
import type { FC } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { BodyL400 } from 'components/UIKit/Typography';

import s from './MainMenuItem.module.scss';

export interface IMainMenuItemProps extends IMainMenuItem {
  className?: string;
}

export const MainMenuItem: FC<IMainMenuItemProps> = ({
  name,
  link,
  target = '_self',
  className,
}) => {
  return (
    <ALink href={link} className={cn(s.link, className)} target={target}>
      <BodyL400>{name}</BodyL400>
    </ALink>
  );
};
