import cn from 'classnames';
import { NotificationHeaderCard } from 'modules/header/components/NotificationHeaderCard';
import { FC } from 'react';

import { pageUrls } from 'constants/pagesUrls';

import { Button } from 'components/UIKit/Button';
import { FunctionalButton } from 'components/UIKit/FunctionalButton';
import { Illustration } from 'components/UIKit/Illustration';
import { BodyL700, H3 } from 'components/UIKit/Typography';

import { INotification } from 'sp-redux/slices/notifications/types';

import s from './NotificationsDropdown.module.scss';

interface INotificationsDropdown {
  notifications: INotification[];
  notificationsCounts: number;
  isOpen: boolean;
  onClose: () => void;
}

export const NotificationsDropdown: FC<INotificationsDropdown> = ({
  notifications,
  notificationsCounts,
  isOpen,
  onClose,
}) => {
  return (
    <div
      className={cn(s.wrapper, {
        [s.wrapperOpen]: isOpen,
      })}
    >
      <div className={s.titleWrapper}>
        <H3>Ваши уведомления</H3>
        <FunctionalButton
          className={s.closeIcon}
          onClick={onClose}
          icon="closeOutline24x24"
          color="var(--palettes-gray-180)"
        />
      </div>
      <div className={s.notificationsWrapper}>
        {!notifications.length || !notificationsCounts ? (
          <div className={s.notNotificationsWrapper}>
            <Illustration
              className={s.illustration}
              imageType="noNotification"
            />
            <BodyL700 className={s.notNotificationsText}>
              Нет новых уведомлений
            </BodyL700>
          </div>
        ) : (
          <>
            {notifications.map((item: INotification) => (
              <NotificationHeaderCard
                key={item.id}
                title={item.title}
                text={item.text}
                url={item.url || pageUrls.notifications.index}
                created_at={item.created_at}
              />
            ))}
          </>
        )}
        <div className={s.buttonWrapper}>
          <Button
            className={s.button}
            look="secondary"
            href={pageUrls.notifications.index}
            target="_self"
            size="medium"
          >
            Другие уведомления
          </Button>
        </div>
      </div>
    </div>
  );
};
