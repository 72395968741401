export interface ISendRoyaltyOtcResponse {
  cache_ttl: number;
  ttl_resending: number;
}

export enum EBooksTypes {
  book = 'book',
  audio = 'audiobook',
  printedBook = 'printed_book',
}

export enum EStatisticsTableTabs {
  reading = 'reading',
  royalty = 'royalty',
}
