import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux/index';
import { deleteSeriesThunk } from 'sp-redux/thunks/series/deleteSeries';
import { getSeriesThunk } from 'sp-redux/thunks/series/getSeries';
import { getSeriesCountThunk } from 'sp-redux/thunks/series/getSeriesCount';
import { patchSeriesDetailsThunk } from 'sp-redux/thunks/series/patchSeriesDetails';

import type { ISeriesStateType } from './types';

const initialState: ISeriesStateType = {
  isFetched: false,
  isFetching: true,
  isCountersFetched: false,
  isCountersFetching: true,
  counters: { total: 0, in_progress: 0, published: 0 },
  seriesList: [],
  pagination: { count: 0, next_page: '', previous_page: '' },
};

const seriesSlice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    clearSeriesList: state => {
      state.seriesList = [];
    },

    createSeriesSuccess: (state, action) => {
      state.seriesList = [...state.seriesList, action.payload];
    },
  },
  extraReducers: builder => {
    builder.addCase(patchSeriesDetailsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      const seriesId = action.payload.result.id;

      state.seriesList = state.seriesList.map(seriesItem =>
        seriesItem.id === seriesId ? action.payload.result : seriesItem,
      );
    });

    builder.addCase(patchSeriesDetailsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getSeriesThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.seriesList = [...state.seriesList, ...action.payload.result];

      if (action.payload.pagination) {
        state.pagination = action.payload.pagination;
      }
    });

    builder.addCase(getSeriesThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getSeriesCountThunk.pending, state => {
      state.isCountersFetching = true;
    });

    builder.addCase(getSeriesCountThunk.fulfilled, (state, action) => {
      state.isCountersFetched = true;
      state.isCountersFetching = false;

      if (Object.keys(action.payload.result).length > 0) {
        const { total, in_progress, published } = action.payload.result;

        state.counters = {
          total,
          in_progress,
          published,
        };
      }
    });

    builder.addCase(deleteSeriesThunk.fulfilled, (state, action) => {
      const idToDelete = action.meta.arg as number;
      const result = {
        ...state,
        seriesList: state.seriesList.filter(item => item.id !== idToDelete),
      };
      if (state.pagination) {
        result.pagination = {
          ...state.pagination,
          count: state.pagination.count - 1,
          next_page:
            state.pagination.count - 1 === 0 ? '' : state.pagination.next_page,
        };
      }
      return result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<ISeriesStateType>) => {
        return action.payload.series;
      },
    );
  },
});

export const { clearSeriesList, createSeriesSuccess } = seriesSlice.actions;

export const seriesReducer = seriesSlice.reducer;
