import type { IFilterGroupName } from 'sp-redux/slices/moderator/types';

export const filtersGroup: Record<string, IFilterGroupName> = {
  status_group: {
    send_agent: {
      name: 'Отправлена агенту',
      checked: false,
    },
    approved_moderator: {
      name: 'Одобрена модератором',
      checked: false,
    },
    verified_agent: {
      name: 'Проверяется агентом',
      checked: false,
    },
  },
  processing_time: {
    twenty_four: {
      name: 'successTimer',
      checked: false,
    },
    sixteen: {
      name: 'warningTimer',
      checked: false,
    },
    eight: {
      name: 'dangerTimer',
      checked: false,
    },
    time_is_up: {
      name: 'alertTimer',
      checked: false,
    },
  },
  my_checks_group: {
    my_checks: {
      name: 'Я:',
      checked: false,
    },
  },
  license_type: {
    exclusive: {
      name: 'Эксклюзивная',
      checked: false,
    },
    non_exclusive: {
      name: 'Базовая',
      checked: false,
    },
    free: {
      name: 'Бесплатная',
      checked: false,
    },
    draft: {
      name: 'Черновик',
      checked: false,
    },
  },
  author_status_group: {
    not_filled: {
      name: 'Не заполнен',
      checked: false,
    },
    not_confirmed: {
      name: 'Не подтвержден',
      checked: false,
    },
    confirmed: {
      name: 'Подтвержден',
      checked: false,
    },
  },
  who_returned_group: {
    author: {
      name: 'Автор',
      checked: false,
    },
    main_moderator: {
      name: 'Главный модератор',
      checked: false,
    },
  },
};

export const booksCommonLineReturns = [
  'timerGroup',
  'moderatorGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'postModerationSwitch',
];

export const booksCommonLineNew = [
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'postModerationSwitch',
];

export const profilesCommonLineNew = [
  'timerGroup',
  'profileAuthorAutocomplete',
];

export const profilesCommonLineReturns = [
  'timerGroup',
  'moderatorGroup',
  'profileAuthorAutocomplete',
];

export const booksMyLineReturns = [
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'whoReturnGroup',
  'postModerationSwitch',
  'plagiarySwitch',
];

export const booksMyLineNew = [
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'postModerationSwitch',
  'plagiarySwitch',
];

export const profilesMyLineNew = ['timerGroup', 'profileAuthorAutocomplete'];

export const profilesMyLineReturns = [
  'timerGroup',
  'profileAuthorAutocomplete',
];

export const booksFinalLineReturns = [
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'whoReturnGroup',
  'postModerationSwitch',
  'plagiarySwitch',
];

export const booksFinalLinePending = [
  'statusGroup',
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'whoReturnGroup',
  'postModerationSwitch',
  'plagiarySwitch',
];

export const booksFinalLineNew = [
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'whoReturnGroup',
  'postModerationSwitch',
  'plagiarySwitch',
];

export const booksFinalLineErrors = [
  'timerGroup',
  'profileAuthorAutocomplete',
  'licenceGroup',
  'authorStatusGroup',
  'whoReturnGroup',
  'postModerationSwitch',
  'plagiarySwitch',
];
