import cn from 'classnames';
import type { FC } from 'react';
import FocusLockUI from 'react-focus-lock/UI';
import ReactModal from 'react-modal';
import { sidecar } from 'use-sidecar';

import { Caption, Subtitle } from 'components/UIKit/Typography';

import SvgCrossIcon from './cross-icon.svg';
import type {
  IModalProps,
  IModalCSSProperties,
  TModalInnerComponent,
} from './types';

import s from './Modal.module.scss';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('body');

const FocusLockSidecar = sidecar(
  () => import(/* webpackPrefetch: true */ 'react-focus-lock/sidecar'),
);

export const Modal: FC<IModalProps> = props => {
  const {
    className,
    children,
    style,
    title,
    width,
    height,
    isOpen,
    onRequestClose,
    shouldCloseOnEsc,
    shouldCloseOnOverlayClick,
    focusLockUIClassName,
    ...restProps
  } = props;

  const customContentStyle: IModalCSSProperties = {};

  if (width) {
    customContentStyle['--modal-width'] = width;
  }

  if (height) {
    customContentStyle['--modal-height'] = height;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName={s.root}
      overlayClassName={s.overlay}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      className={cn(s.modal, 'shadowLevel2', className)}
      style={{
        overlay: {
          ...style?.overlay,
        },
        content: {
          ...customContentStyle,
          ...style?.content,
        },
      }}
      {...restProps}
    >
      <FocusLockUI
        sideCar={FocusLockSidecar}
        className={cn('modalWrapper', focusLockUIClassName)}
      >
        {onRequestClose && (
          <button
            type="button"
            className={s.closeButton}
            onClick={onRequestClose}
            aria-label="Закрыть модальное окно"
          >
            <SvgCrossIcon width="15px" height="15px" aria-hidden="true" />
          </button>
        )}

        {title && <ModalTitle>{title}</ModalTitle>}

        <ModalContent className="modalContent">{children}</ModalContent>
      </FocusLockUI>
    </ReactModal>
  );
};

export const ModalTitle: TModalInnerComponent = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <header
      className={cn(s.header, className)}
      aria-labelledby="modal-title"
      {...restProps}
    >
      <Subtitle Tag="h2" className={s.headerTitle} id="modal-title">
        {children}
      </Subtitle>
    </header>
  );
};

export const ModalContent: TModalInnerComponent = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <section className={cn(s.content, className)} {...restProps}>
      {children}
    </section>
  );
};

export const ModalFooter: TModalInnerComponent = ({
  children,
  className,
  caption,
  ...restProps
}) => {
  return (
    <footer className={cn(s.footer, className)} {...restProps}>
      {caption && <Caption className={s.footerCaption}>{caption}</Caption>}
      <div className={s.actionWrapper}>{children}</div>
    </footer>
  );
};

export const ModalContentWrapper: TModalInnerComponent = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <div className={cn(s.contentWrapper, className)} {...restProps}>
      {children}
    </div>
  );
};
