export interface IEventParameters {
  [key: string]: string | number | boolean | object;
}

export interface IEventSource {
  debug: boolean;
  [key: string]: string | number | boolean | object;
}

export enum EHost {
  web = 'web',
  pda = 'pda',
}

export interface IAnalyticsEventAdditionalData {
  event_id: string;
  event_date: string;
  host: EHost;
  source: IEventSource;
}

export interface IAnalyticsEventCoreData {
  event_type: string;
  event_parameters: IEventParameters;
  user_id: number;
  user_uuid?: string;
  registration_date?: string;
  sid?: string;
  host_region?: string;
  os_version?: string;
  varioqube_ab_variants?: number[];
  varioqube_uid?: string;
}

export interface IAnalyticsEvent
  extends IAnalyticsEventCoreData,
    IAnalyticsEventAdditionalData {}
