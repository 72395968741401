import type {
  IAPIResponse,
  IApiResponseErrorField,
  IPaginationType,
} from 'app.types';
import { EBooksTypes } from 'modules/statistics/types';

export interface ITitles {
  [key: string]: string;
}

export enum EStatisticsTypes {
  views = 'art_card_views_count',
  trialReads = 'trial_reads_count',
  likes = 'likes_count',
  readings = 'hits_count',
  downloads = 'sales_count',
  royalty = 'royalty',
  readingDepth = 'reads_avg_percent',
}

export interface IGetStatisticsChartRequestType {
  art_type: EBooksTypes;
  metrics: EStatisticsTypes[];
  from_date: number;
  to_date: number;
}

export interface IStatisticsChartType {
  data: IGetStatisticsChartResponseType;
  errors: IApiResponseErrorField[];
}

export interface IGetStatisticsChartResponseType {
  dates: string[];
  sales_count: number[];
  sales_count_sum: number;
  hits_count: number[];
  hits_count_sum: number;
  royalty: number[];
  royalty_sum: number;
  likes_count: number[];
  likes_count_sum: number;
  art_card_views_count: number[];
  art_card_views_count_sum: number;
  trial_reads_count: number[];
  trial_reads_count_sum: number;
  reads_avg_percent: number[];
  reads_avg_percent_sum: number;
}

export interface IGetRawSumsRequestType {
  art_type: EBooksTypes;
  from_date: number;
  to_date: number;
}

export interface IGetRawSumsResponseType {
  sales_count: number;
  hits_count: number;
  royalty: number;
  likes_count: number;
  art_card_views_count: number;
  trial_reads_count: number;
  reviews_count: number;
  reads_avg_percent: number;
}

export interface IGetStatisticsChartQueryParamsType {
  art_ids?: number[];
  user_id?: string | string[];
}

export interface IReadingStatisticsData {
  bookName: string;
  bookLink: string;
  showings: number;
  views: number;
  previews: number;
  readings: number;
  downloads: number;
  royalty: number;
}

export interface IGetReadingStatisticsRequestType {
  art_type: EBooksTypes;
  from_date: number;
  to_date: number;
  limit: number;
  offset: number;
}

export interface IGetReadingStatisticsResponseType {
  result: IReadingStatisticsTableRow[];
  pagination: IPaginationType;
  errors: IApiResponseErrorField[];
}

export interface IReadingStatisticsTableRow {
  art_cover_url: string;
  art_id: number;
  art_name: string;
  sales_count: number;
  hits_count: number;
  royalty: number;
  likes_count: number;
  art_card_views_count: number;
  trial_reads_count: number;
  reads_avg_percent: number;
}

export type TGetReadingStatisticsResponse = Required<
  IAPIResponse<IReadingStatisticsTableRow[]>
>;

export interface IGetRoyaltyStatisticsQueryType {
  date_from: number;
  date_to: number;
  user_id?: string | string[];
  book_ids?: number[];
  limit?: number;
  offset?: number;
}

export interface IGetRoyaltyStatisticsSumsType {
  date_from: number;
  date_to: number;
  user_id?: string | string[];
  book_ids?: string | string[];
  limit?: number;
  offset?: number;
}

export interface IGetRoyaltyStatisticsResponseType {
  results: IRoyaltyStatisticsTableRow[];
  pagination: {
    count: number;
    next_page: string;
    previous_page: string;
  };
  errors: IApiResponseErrorField[];
  sums: IRoyaltySums;
}

export type TGetRoyaltyStatisticsResponse = Required<
  IAPIResponse<IRoyaltyStatisticsTableRow[]>
>;

export interface IRoyaltyStatisticsTableRow {
  art_id: number;
  art_name: string;
  art_cover_url: string;
  date: number;
  channel: string;
  sales: number;
  online_library_hits: number;
  sales_amount: string;
  online_library_amount: string;
  royalty: string;
}

export interface IRoyaltySums {
  online_library_amount: string;
  online_library_hits: number;
  royalty: string;
  sales_count: number;
  sales_sum: string;
}

export interface IGetRoyaltyOverallResponseType {
  paid_royalty: string;
  with_current_period_royalty: string;
  locked_part: string;
  is_payout_enabled: boolean;
}

export interface IGetRoyaltyOverallQueryType {
  user_id?: string | string[];
}

export interface IGetStatisticsAccessibilityResponse {
  forbidden: boolean;
}

export interface IRoyaltyReportResponse {
  report: string;
  period: string;
}

export interface IRoyaltyDraftResponse {
  id: number;
  cover_url: string;
  title: string;
  progress: number;
  earned: string;
  frozen: string;
  comments: {
    type: string;
    message: string;
  };
}

export interface IRoyaltyPayoutResponse {
  id: number;
  amount: string;
  status: string;
  datetime: number;
}

export interface IRoyaltyBannerResponse {
  type: string;
  header: string;
  text: string;
}
