import Image from 'next/image';
import type { FC } from 'react';

import { Illustration } from 'components/UIKit/Illustration';
import {
  BodyL700,
  BodyL400,
  H4,
  HighlightTitle,
  HighlightSubtitle,
} from 'components/UIKit/Typography';

import { typografFixText } from 'utils/typograf';

import { SUCCESS_STORIES } from './constants';

import s from './SuccessStoriesBlock.module.scss';

export const SecondStory: FC = () => {
  const story = SUCCESS_STORIES[1];

  return (
    <>
      <div className={s.about}>
        <Image src={story.src} height={56} width={56} alt={story.name} />
        <div className={s.info}>
          <BodyL700>{story.name}</BodyL700>
          <BodyL400 className={s.genre}>{story.genre}</BodyL400>
        </div>
      </div>

      <HighlightTitle className={s.modalTitle}>
        {typografFixText(story.text)}
      </HighlightTitle>
      <H4>{typografFixText('До того, как я стал автором Литрес')}</H4>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Большую часть жизни я работал в пиаре, а ещё всю жизнь любил читать. Короче, это было неизбежно. Тридцать три года я смотрел в чужой текст, а потом попробовал написать свой.',
        )}
      </BodyL400>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Был разгар коронавируса, когда мне надоело жить в режиме работа-дом (тогда путь лежал от кухни до гостиной) и я возненавидел себя за отсутствие хобби. Тогда-то я и вспомнил детские мечты стать писателям и школьные фанфики по «Властелину колец». Купил учебник по писательскому мастерству, написал рассказ, знакомым понравилось. Потом сразу сел писать «Пресс-тур», хоррор о журналистах и пиарщике, который не мог издать два года, пока не пришел в Литрес. А как пришел – через год выиграл крупную премию «Электронная буква», сразу с дебютной книгой, по мнению жюри роман был одним из лучших среди 1700 поданных заявок.',
        )}
      </BodyL400>
      <div className={s.modalQuote}>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteTop}
        />
        <HighlightSubtitle className={s.modalQuoteText}>
          {typografFixText(
            'С дебютным романом случилась история успеха, и я надеюсь, что она вдохновит и других людей.',
          )}
        </HighlightSubtitle>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteBottom}
        />
      </div>
      <H4>
        {typografFixText('Как изменилась моя жизнь после публикации на Литрес')}
      </H4>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Я понимаю, что у меня нетипичная история. В моем анамнезе нет литинститута, филологического образования, и иногда у меня плохо с запятыми. Хоррор – не самый популярный жанр. В продвижении первой книги мне помогли мои профессиональные навыки, десять лет я пиарил чужие продукты, а потом попробовал свой собственный. Я сочетаю писательство с основной профессией, пишу урывками, в свободное время, а большинство профессиональных авторов так не делает. Тем не менее, с дебютным романом случилась история успеха, и я надеюсь, что она вдохновит и других людей. У вас может быть самая скучная офисная работа, зато вечерами вы можете оторваться по полной создавая свои миры!',
        )}
      </BodyL400>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Конечно же, я собираюсь продолжать. Сейчас пишу мистический детектив в декорациях современной Москвы, завязанный на славянской мифологии. Уверен, он будет намного круче первой книги, мне нравится наблюдать как я расту как автор. Этот рост и положительные рецензии – моя верхушка пирамиды Маслоу.',
        )}
      </BodyL400>
    </>
  );
};
