import { logFirebaseEvent } from 'analytics/firebase';
import {
  sendGoogleAnalytics,
  sendYandexAnalytics,
} from 'analytics/sendAnalytics';
import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, MouseEvent } from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typed from 'typed.js';

import { MIN_WINDOW_WITH_FOR_AUTH_MODAL_AND_BURGER } from 'constants/numbers';
import { pageUrls } from 'constants/pagesUrls';

import { Button } from 'components/UIKit/Button';
import { Illustration } from 'components/UIKit/Illustration';

import type { TAppState } from 'sp-redux';
import { openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';

import { isBrowser } from 'utils/environment';

import s from './WaitBlock.module.scss';

export const WaitBlock: FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { userInfo } = useSelector((appState: TAppState) => ({
    userInfo: appState.userInfo,
  }));

  const eventParams = {
    source: 'main_under_animation',
    user_type: userInfo.isLogged ? 'auth' : 'no_auth',
  };

  const handleSendAnalytics = (): void => {
    const action = 'main_click_become_author';
    logFirebaseEvent(action, eventParams);
    sendGoogleAnalytics({
      action,
    });
    sendYandexAnalytics({
      action,
      goalParams: {
        ...eventParams,
        user_id: userInfo.profile.uuid ?? '',
      },
    });
  };

  const openSignUpModalHandler = useCallback(
    (evt: MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => {
      if (
        isBrowser &&
        window.innerWidth >= MIN_WINDOW_WITH_FOR_AUTH_MODAL_AND_BURGER &&
        !router.asPath.includes(pageUrls.account.registration)
      ) {
        evt.preventDefault();
        dispatch(openModal(EUiModalTypes.signUp));
        logFirebaseEvent('popup_registration_view', {
          source: 'registration_form',
        });
        handleSendAnalytics();
      }
    },
    [dispatch],
  );

  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['книги', 'черновики', 'аудиокниги'],
      typeSpeed: 80,
      loop: true,
    });

    return (): void => {
      typed.destroy();
    };
  }, []);

  const headphonesRef = useRef<HTMLDivElement>(null);
  const brushRef = useRef<HTMLDivElement>(null);
  const bookRef = useRef<HTMLDivElement>(null);
  const makeMoneyRef = useRef<HTMLDivElement>(null);
  const letterRef = useRef<HTMLDivElement>(null);
  const pictureRef = useRef<HTMLDivElement>(null);

  const refs = [
    headphonesRef,
    brushRef,
    bookRef,
    makeMoneyRef,
    letterRef,
    pictureRef,
  ];

  const parallax = (): void => {
    const { scrollY, innerHeight } = window;

    refs.map(element => {
      if (element.current) {
        const position = element.current.getAttribute('data-value');
        const y = (innerHeight - scrollY * Number(position)) / 40;
        element.current.style.transform = `translateY(${y}px)`;
      }
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', parallax);
    return (): void => {
      document.removeEventListener('scroll', parallax);
    };
  }, []);

  const handleButtonPublishClick = (): void => {
    handleSendAnalytics();
    router.push(pageUrls.books.index);
  };

  return (
    <div className={s.wrapper} data-testid="waitBlock">
      <div className={s.textBlock}>
        <div>Читатели</div>
        <div className={s.word}>ждут </div>
        <span ref={el} className={s.typedWord} />
      </div>

      {!userInfo.isLogged && (
        <Button
          href="/account/registration/"
          onClick={openSignUpModalHandler}
          target="_self"
          className={s.button}
          dataTestId="waitBlock_button-becomeAuthor"
        >
          Стать автором
        </Button>
      )}

      {userInfo.isLogged && (
        <Button
          className={s.button}
          onClick={handleButtonPublishClick}
          dataTestId="waitBlock_button-publish"
        >
          Опубликовать
        </Button>
      )}

      <div
        ref={headphonesRef}
        data-value={-5}
        className={cn(s.icon, s.iconHeadphones)}
      >
        <Illustration
          imageType="headphones"
          className={s.iconHeadphonesRotate}
          width={116}
          height={116}
        />
      </div>

      <div ref={brushRef} data-value={-5} className={cn(s.icon, s.iconBrush)}>
        <Illustration imageType="brush" width={112} height={112} />
      </div>

      <div ref={bookRef} data-value={-5} className={cn(s.icon, s.iconBook)}>
        <Illustration imageType="book" />
      </div>

      <div
        ref={makeMoneyRef}
        data-value={-5}
        className={cn(s.icon, s.iconPrint)}
      >
        <Illustration imageType="makeMoney" width={200} height={200} />
      </div>

      <div ref={letterRef} data-value={-5} className={cn(s.icon, s.iconLetter)}>
        <Illustration imageType="letter" className={s.iconLetterRotate} />
      </div>

      <div
        ref={pictureRef}
        data-value={-5}
        className={cn(s.icon, s.iconPicture)}
      >
        <Illustration
          imageType="picture"
          className={s.iconPictureRotate}
          width={163}
          height={163}
        />
      </div>
    </div>
  );
};
